import * as Icon from "@iyk/icons"
import * as ReactToast from "@radix-ui/react-toast"
import * as React from "react"

import { cva, type VariantProps } from "../cva.ts"

const ToastProvider = ReactToast.Provider

const ToastViewport = React.forwardRef<
  React.ElementRef<typeof ReactToast.Viewport>,
  React.ComponentPropsWithoutRef<typeof ReactToast.Viewport>
>(({ className, ...props }, ref) => (
  <ReactToast.Viewport
    ref={ref}
    className={classForToastViewport({
      className,
    })}
    {...props}
  />
))
ToastViewport.displayName = ReactToast.Viewport.displayName

const classForToastViewport = cva(
  "fixed bottom-0 z-[100] flex max-h-screen w-full flex-col-reverse p-4 gap-y-2 sm:left-0 sm:flex-col md:max-w-[420px]",
)

export type ToastState = "info" | "success" | "warning" | "error" | "loading"

const toastVariants = cva(
  "group pointer-events-auto relative w-full flex overflow-hidden rounded-md border p-3 space-x-3 transition-all data-[swipe=cancel]:translate-x-0 data-[swipe=end]:translate-x-[var(--radix-toast-swipe-end-x)] data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] data-[swipe=move]:transition-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[swipe=end]:animate-out data-[state=closed]:fade-out-80 data-[state=closed]:slide-out-to-bottom-full data-[state=open]:slide-in-from-bottom-full shrink-0",
  {
    variants: {
      variant: {
        default: [
          "bg-gray-12 text-gray-1",
          "dark:bg-gray-2-dark dark:text-gray-12-dark",
        ],
        destructive: "destructive border-tomato-4 bg-tomato-3 text-tomato-11",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  },
)

const Toast = React.forwardRef<
  React.ElementRef<typeof ReactToast.Root>,
  React.ComponentPropsWithoutRef<typeof ReactToast.Root> &
    VariantProps<typeof toastVariants>
>(({ className, variant, ...props }, ref) => {
  return (
    <ReactToast.Root
      ref={ref}
      className={toastVariants({ variant, className })}
      {...props}
    />
  )
})
Toast.displayName = ReactToast.Root.displayName

const ToastAction = React.forwardRef<
  React.ElementRef<typeof ReactToast.Action>,
  React.ComponentPropsWithoutRef<typeof ReactToast.Action>
>(({ className, ...props }, ref) => (
  <ReactToast.Action
    ref={ref}
    className={classForToastAction({ className })}
    {...props}
  />
))
ToastAction.displayName = ReactToast.Action.displayName

const classForToastAction = cva([
  "shrink-0 px-1.5 py-1 text-xs text-gray-1-light rounded-md border border-gray-11-light cursor-pointer outline-none transition-colors disabled:pointer-events-none disabled:opacity-50",
  "group-[.destructive]:border-tomato-11 group-[.destructive]:text-tomato-11",
])

const ToastClose = React.forwardRef<
  React.ElementRef<typeof ReactToast.Close>,
  React.ComponentPropsWithoutRef<typeof ReactToast.Close>
>(({ className, ...props }, ref) => (
  <ReactToast.Close
    ref={ref}
    className={classForToastClose({ className })}
    toast-close=""
    {...props}
  >
    <Icon.Cross className="h-4 w-4" />
  </ReactToast.Close>
))
ToastClose.displayName = ReactToast.Close.displayName

const classForToastClose = cva(
  "absolute right-1 top-1 p-1 text-gray-8-light transition-opacity hover:text-gray-1-light focus:opacity-100 focus:outline-none group-[.destructive]:text-tomato-8-light hover:group-[.destructive]:text-tomato-9-light",
)

const ToastTitle = React.forwardRef<
  React.ElementRef<typeof ReactToast.Title>,
  React.ComponentPropsWithoutRef<typeof ReactToast.Title>
>(({ className, ...props }, ref) => (
  <ReactToast.Title
    ref={ref}
    className={classForToastTitle({ className })}
    {...props}
  />
))
ToastTitle.displayName = ReactToast.Title.displayName

const classForToastTitle = cva(["text-sm"])

const ToastDescription = React.forwardRef<
  React.ElementRef<typeof ReactToast.Description>,
  React.ComponentPropsWithoutRef<typeof ReactToast.Description>
>(({ className, ...props }, ref) => (
  <ReactToast.Description
    ref={ref}
    className={classForToastDescription({ className })}
    {...props}
  />
))
ToastDescription.displayName = ReactToast.Description.displayName

const classForToastDescription = cva([
  "text-sm",
  "text-gray-8",
  "dark:text-gray-11-dark",
  "group-[.destructive]:text-tomato-11",
])

const StateIcon = {
  success: Icon.Check,
  error: Icon.CrossCircled,
  info: Icon.InfoCircled,
  warning: Icon.ExclamationTriangle,
  loading: Icon.LoadingSpinner,
}

const ToastIcon: React.FC<{ state: ToastState; className?: string }> = (props) => {
  const { state, className } = props
  const Icon = StateIcon[state]

  return (
    <div className={classForToastIcon({ className })}>
      <Icon className="h-4 w-4 text-gray-1-light group-[.destructive]:text-tomato-11" />
    </div>
  )
}

const classForToastIcon = cva("shrink-0")

type ToastProps = React.ComponentPropsWithoutRef<typeof Toast>

type ToastActionElement = React.ReactElement<typeof ToastAction>

export type { ToastActionElement, ToastProps }

export {
  Toast,
  ToastAction,
  ToastClose,
  ToastDescription,
  ToastIcon,
  ToastProvider,
  ToastTitle,
  ToastViewport,
}
